<template>
  <div class="register">
    <div
      style="
        width: 100%;
        height: 30px;
        font-size: 30px;
        color: white;
        position: fixed;
        top: 60px;
        left: 78px;
      "
    >
      智慧科研信息管理系统
    </div>
    <canvas
      id="canv"
      style="width: 100%; height: 100%; overflow: hidden"
    ></canvas>
    <div class="box">
      <div class="register-border">
        <span class="span">智慧科研信息管理系统</span>
        <div class="text-box">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            class="demo-ruleForm"
          >
            <el-form-item prop="username">
              <el-input
                placeholder="请输入用户名"
                v-model="ruleForm.username"
                class="login-user"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                placeholder="请输入密码"
                v-model="ruleForm.password"
                show-password
                class="login-lock"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="Captcha">
              <div style="position: relative">
                <el-input
                  type="text"
                  v-model="ruleForm.Captcha"
                  style="background-color: transparent; border: 0"
                  placeholder="请输入验证码"
                  class="login-ma"
                >
                </el-input>
                <div class="code" @click="refreshCode">
                  <s-identify :identifyCode="identifyCode"></s-identify>
                </div>
              </div>
            </el-form-item>
          </el-form>
           <p style="margin-bottom: 20px;color: #666;cursor:pointer;" @click="Forgetthesecret">忘记密码？</p>
          <el-button class="button" :loading="loading" type="primary" @click="getUserLogin">登录</el-button>
        </div>
      </div>
    </div>
    <div style="width:400px;position: absolute;bottom: 2%;left: 50%;margin-left: -200px" class="footer">
      <p>Copyright © 2016-2021 SJS TECH. All Rights Reserved.</p>
      <p>鄂ICP备15019034号-4</p>
      <p>武汉赛捷思科技有限公司 版权所有</p>
    </div>
  </div>
</template>
<script>
import api from "@/api/url";
import SIdentify from "@/components/identify";
export default {
  data() {
    var checkname = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("用户名为必填项"));
      } else {
        callback();
      }
    };
    var checkpwd = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("密码为必填项"));
      } else {
        callback();
      }
    };
    var checkCaptcha = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(" 验证码为必填项"));
      } else if (value != localStorage.getItem("Captcha")) {
        callback(new Error("请输入正确验证码"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        username: "",
        password: "",
        Captcha: "",
      },
      rules: {
        username: [{ validator: checkname, trigger: "blur" }],
        password: [{ validator: checkpwd, trigger: "blur" }],
        Captcha: [{ validator: checkCaptcha, trigger: "blur" }],
      },
      identifyCodes: "1234567890",
      identifyCode: "",
      Captcha: "",
      username: "",
      password: "",
      projectId: "",
      sliders: [],
      value: "",
      dialogVisible: false,
      loading: false
    };
  },
  components: { SIdentify },
  mounted() {
    this.identifyCode = "";
    this.makeCode(this.identifyCodes, 4);
  },
  methods: {
    Forgetthesecret(){
      console.log(111);
       this.$router.push({name:"VerificationCode"})
    },
    changeValue(key) {
      this.projectId = key;
      this.getUserLogin();
    },
    getUserLogin() {
      let parame = {
        LoginText: this.ruleForm.username,
        Password: this.ruleForm.password,
      };
      if (this.projectId) {
        parame.ProjectId = this.projectId;
      }
      this.submintLogin(parame);
    },
    /* 图形验证码 */
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode +=
          this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
      }
      localStorage.setItem("Captcha", this.identifyCode);
    },

    submintLogin(parame) {
      if (
        this.ruleForm.Captcha !== localStorage.getItem("Captcha") ||
        this.ruleForm.password === "" ||
        this.ruleForm.username === ""
      ) {
        this.refreshCode()
        this.$message.error("请输入验证信息");
        return false;
      }
      this.loading = true
      api.login(parame).then((res) => {
        console.log(res);
        if (res.data.Status == 1) {
          console.log(res);
          window.sessionStorage.setItem("Token", res.data.ExtraData.token);
          window.sessionStorage.setItem("Id", res.data.ExtraData.userinfo.Id);
          window.sessionStorage.setItem("HospitalName",res.data.ExtraData.purchaseEntity.HospitalName);
          window.sessionStorage.setItem("UserName",res.data.ExtraData.userinfo.UserName);
          window.sessionStorage.setItem("PurchaseId",res.data.ExtraData.userinfo.PurchaseId);
          window.sessionStorage.setItem("Type",res.data.ExtraData.userinfo.Type);
          this.$message.success("登录成功");
          this.$router.push("/HomePage");
          this.loading = false
          return false;
        } else if (res.data.Status == -1) {
          this.$message.error(res.data.Message);
          this.refreshCode()
          this.loading = false
        }
      }).catch(() => {
        this.refreshCode()
        this.loading = false
      })
    },
  },
};
</script>
<style lang='scss' scoped>
.register {
  position: relative;
  width: 100%;
  height: 100%;
  background: url("../assets/image/loginBg.jpeg") center no-repeat;
  background-size: cover;
  .box {
    position: absolute;
    background: #ffffff;
    border-radius: 13px;
    .title {
      img {
        display: block;
        margin: 0 auto;
        object-fit: cover;
      }
    }
    .register-border {
      .span {
        display: block;
        width: 100%;
        height: 30px;
        font-size: 30px;
        color: #252b31;
        line-height: 51px;
        text-align: center;
      }
      div.forget {
        display: flex;
        width: 80%;
        margin: 0px auto;
        justify-content: space-between;
        span {
          transform: translateY(1%);
          font-size: 18px;
          cursor: pointer;
        }
      }
      .text-box {
        height: 50px;
        margin-left: 81px;
         /deep/.el-input {
          width: 380px;
          height: 50px;
          border-radius: 4px;
          .el-input__inner {
            border: 1px solid #cfcfcf;
            padding-left: 32px;
            font-size: 18px;
            height: 50px;
          }
          .el-input__prefix {
            color: rgb(32, 163, 245);
            .el-input__icon {
              font-size: 14px;
              line-height: 35px;
              margin-top: -2px;
            }
          }
        }
      }
      .button {
        //display: block;
        width: 380px;
        height: 51px;
        //line-height: 40px;
        //color: #fff;
        //border: 0;
        font-size: 18px;
        //background: #1890ff;
        //border-radius: 4px;
      }
    }
  }

  ::v-deep .el-dialog {
    ::v-deep .el-dialog__header {
      padding: 0;
    }
    .el-dialog__body {
      .el-select {
        width: 100%;
      }
    }
  }
}
.code {
  position: absolute;
  top: 9%;
  right: 20%;
}
::v-deep .el-input.login-user .el-input__inner {
  padding-top: 4px;
  background: url("../assets/image/loginuser.png") no-repeat 14px center;
}
::v-deep .el-input.login-lock .el-input__inner {
  padding-top: 2px;
  background: url("../assets/image/loginlock.png") no-repeat 14px center;
}
::v-deep .el-input.login-ma .el-input__inner {
  padding-top: 2px;
  background: url("../assets/image/yanzma.png") no-repeat 14px center;
}
@media screen and (max-width: 768px) {
  .box {
    width: 500px;
    height: 45%;
    top: 17%;
    padding-bottom: 70px;
    .register-border {
      .span {
        margin-top: 30px;
        margin-bottom: 20px;
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .box {
    width: 542px;
    right: 13%;
    //height: 45%;
    height: 484px;
    top: 15%;
    padding-bottom: 40px;
    .register-border {
      .span {
        margin-top: 80px;
        margin-bottom: 54px;
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .box {
    top: 17%;
    width: 542px;
    right: 15%;
    height: 484px;
    padding-bottom: 44px;
    .register-border {
      .span {
        margin-top: 94px;
        margin-bottom: 64px;
      }
    }
  }
}
.footer {
  font-size: 14px;
  color: #ecf5ff;
  p {
    width: 400px;
    text-align: center;
  }
}
</style>
